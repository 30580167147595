<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('pay_pix') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          to="Record"
        >
          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-btn>
      </v-toolbar>


      <v-sheet
        color="nav_bg"
        class="pa-5 mt-3 text-center rounded-t-xl"
      >
        <div class="d-flex align-center justify-space-between text-body-2 text--disabled">
          <div>{{ $t('fen_yue') }}:</div>
          <div>
            <img
              width="16"
              height="16"
              src="../assets/img/icons/coin.svg"
              class="mx-1 mb-n1"
            >
            {{ agentinfo.assets?.available }} {{ $t('fen') }}
          </div>
        </div>
      </v-sheet>
      

      <v-sheet
        color="#fff"
        class="pa-3 mb-3 rounded-b-xl"
      >
        <div>
          <v-subheader>
            {{ $t('pay_jine') }}
          </v-subheader>
          <v-text-field
            v-model.number="amount"
            type="number"
            filled
            rounded
            :suffix="$store.state.agentinfo.currency"
            background-color="app_bg"
            hide-details="auto"
            :placeholder="$t('pay_shurujine')"
            class="mb-3"
            @keyup="amountkeyup"
          >
            <template v-slot:prepend-inner>
              <img
                width="22"
                height="22"
                :src="require('../assets/img/icons/'+$store.state.agentinfo.currency+'.png')"
              >
            </template>
          </v-text-field>
          <v-subheader>
            {{ $t('pay_huode') }}
            <v-spacer />
            <span class="text-body-2 opacity-6">
              <v-icon small>mdi-swap-vertical</v-icon>
              1 {{ $store.state.agentinfo.currency }} = {{ fen }} {{ $t('fen') }}
            </span>
          </v-subheader>
          <v-text-field
            v-model.number="points"
            type="number"
            filled
            rounded
            background-color="app_bg"
            :suffix="$t('fen')"
            hide-details="auto"
            class="mb-3"
            @keyup="pointskeyup"
          >
            <template v-slot:prepend-inner>
              <img
                width="22"
                height="22"
                src="../assets/img/icons/coin.svg"
              >
            </template>
          </v-text-field>
        </div>

        <v-divider class="opacity-3 mt-5" />

        <v-subheader>
          {{ $t('pay_shuoming') }}
        </v-subheader>
        <v-list
          dense
          class="text-body-2 opacity-3 pt-0"
        >
          <v-list-item
            style="min-height: 28px;"
          >
            <v-list-item-content class="py-0 my-0">
              {{ $t('pay_xiane') }}
            </v-list-item-content>
            <v-list-item-action class="py-0 my-0">
              {{ min_amount }} ~ {{ max_amount }} {{ agentinfo.currency }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-sheet>

      <v-btn
        block
        x-large
        rounded
        color="primary"
        dark
        class="mt-5"
        :disabled="depositdisabled"
        @click.stop="deposit"
      >
        {{ $t('pay_tijiao') }} {{ amount }} {{ agentinfo.currency }}
      </v-btn>
    </v-container>

    <v-dialog
      v-model="showQR"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        class="app_bg"
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeQR"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('pay_queren') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          :ref="'dialogQR'"
          class="px-3"
        >
          <v-card-subtitle class="pb-0">
            <v-img
              width="150"
              src="../assets/img/logos/pay-pix.png"
              class="mx-auto"
            />
          </v-card-subtitle>
          <div class="text-center pb-3">
            <span class="mr-2">{{ $t('pay_jine') }}:</span>
            <strong>R$</strong>
            <strong
              class="title green--text"
            >
              {{ amount }}
            </strong>
            {{ agentinfo.currency }}

            <v-btn
              icon
              small
              right
              color="grey darken-1"
              @click="doCopy(amount.toString(), 'dialogQR')"
            >
              <v-icon small>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>

          <v-sheet
            rounded
            class="box_bg px-3 pb-5"
          >
            <v-subheader>{{ $t('pay_shuoming') }}</v-subheader>

            <v-alert
              text
              dense
              color="green"
              icon="mdi-clock-fast"
              border="left"
              class="caption"
            >
              {{ $t('pay_pix_tip') }}
            </v-alert>

            <div class="text-center py-3">
              <v-img
                width="150"
                height="150"
                :src="erweima"
                class="mx-auto"
              />
              <small>{{ $t('pay_pix_qr') }}</small>
            </div>

            <v-text-field
              filled
              rounded
              background-color="app_bg"
              hide-details="auto"
              label="PIX CODE"
              class="mb-3"
              readonly
              :value="qr_code_omit"
              @click="doCopy(qr_code, 'dialogQR')"
            />
              
            <v-btn
              block
              depressed
              rounded
              color="primary"
              class="text-none"
              @click="doCopy(qr_code, 'dialogQR')"
            >
              {{ $t('pay_pix_copy') }}
            </v-btn>
          </v-sheet>

          <div class="my-3 text-disabled px-4 opacity-5">
            <h5 class="subtitle-2">
              {{ $t('pay_pix_faq') }}?
            </h5>
            <p>
              {{ $t('pay_pix_faq_ok') }}
            </p>
          </div>
          <div class="text-center pb-5">
            <v-btn
              depressed
              rounded
              dark
              color="grey darken-4"
              @click="closeQR"
            >
              <v-icon left>
                mdi-cash-check
              </v-icon>
              {{ $t('pay_pix_done') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>
  </div>
</template>

<script>
import store from "../store/"
  export default {
    data: () => ({
      fen: '',
      agentinfo: {},
      amount: null,
      points: null,
      min_amount: 0,
      max_amount: 0,
      showQR: false,
      depositdisabled: false,
      showprogress: false,
      exchange_rate: '',
      erweima: '',
      qr_code: '',
      qr_code_omit: '',
    }),

    computed: {

    },
    watch:{
      showprogress (val) {
        val && setTimeout(() => {
          this.showprogress = false
        }, 5000)
      },
    },
    created() {
      //console.log(this.$store.state.exchange_rate)
      //console.log(this.$store.state.agentinfo)
      this.fen = Math.floor((1/(this.$store.state.agentinfo.discount/100))*100)/100
    },
    mounted() {
      this.getagentinfo()
    },
    methods: {
      amountkeyup() {
        this.points = (Math.floor((this.amount*this.fen)*100)/100).toString()
      },
      pointskeyup() {
        this.amount = (Math.floor((this.points/this.fen)*100)/100).toString()
      },
      getagentinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.myinfo(paramObj).then((response) => {
          if(response.code==200){
            this.agentinfo = response.data
            this.min_amount = this.agentinfo.min_amount
            this.max_amount = this.agentinfo.max_amount
            store.commit('agentinfo', this.agentinfo)
          }
        })
      },
      deposit() {
        if(this.amount<this.min_amount || this.amount>this.max_amount){
          this.$snackbar.warning(this.$t('pay_xiane_chao'))
          return false;
        }
        this.showprogress = true
        this.depositdisabled = true
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          amount: this.amount
        }
        this.$server.deposit_pix(paramObj).then((response) => {
          this.showprogress = false
          if(response.code==200){
            this.qr_code = response.data.qr_code
            this.qr_code_omit = response.data.qr_code_omit
            this.erweima = response.data.erweima
            this.showQR = true
          }else{
            this.depositdisabled = false
          }
        })
      },
      closeQR() {
        this.amount = 0
        this.showQR = false
      },
      dblclick() {
        console.log('dblclick')
      },
      doCopy: function (text, ref) {
        text = ''+text//转string
        this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  }
</script>